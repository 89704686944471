import {mapActions, mapGetters} from "vuex";
import {dynamicHead} from '@/mixins/dynamic-head.js'


export default {
  name: "profile",
  mixins: [dynamicHead],
  watch: {
    '$route'(newVal){
      if(newVal){
        this.burger = false
        this.fetchMeta('home').then(()=>{
          this.setParams()
          this.setMeta()
        }).catch(error=>{
          console.log(error)
        })
      }
    }
  },
  data(){
    return{
      params: {
        title: '',
        description: '',
        keywords: '',
        image: '',
      },
      burger: false,
    }
  },
  created() {
    this.fetchMeta('home').then(()=>{
      this.setParams()
      this.setMeta()
    }).catch(error=>{
      console.log(error)
    })
  },
  computed:{
    ...mapGetters({
      meta: 'meta/meta',
    }),

  },
  methods:{
    ...mapActions({
      fetchMeta: 'meta/GET_META',
      logout: 'auth/LOGOUT'
    }),
    setParams(){
      this.params.title = this.meta.metaTitle;
      this.params.description = this.meta.metaDescription;
      this.params.keywords = this.meta.metaKeywords;
      this.params.image = this.meta.metaImage;
    },
  }
}